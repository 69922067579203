import tutorial1 from "../assets/Tutorial1.jpeg";
import tutorial2 from "../assets/Tutorial2.jpeg";
import tutorial3 from "../assets/Tutorial3.jpeg";
import tutorial4 from "../assets/Tutorial4.jpeg";
import tutorial5 from "../assets/Tutorial5.jpeg";
import style from "../styles/modules/AboutPage.module.css";

function TutorialPage({}) {
    return (<>
        <div className={style.pageContainer}>
            <div className={style.tutorial}>
                <h1> Get started on FindU: </h1>
                <h2>Joining FindU</h2>
                <img
                    src={tutorial1}
                    alt="screenshot of site landing page"
                    className={style.screenshot}
                />
                <ul>
                    <li>From any page, simply click on the “Register” button to create a new account.</li>
                    <li>Follow the simple instructions to create your account and fill out information about yourself. In order to create the account, you will need a University of Utah email account.</li>
                    <li>Use the “Sign In” button to access your existing account.</li>
                </ul>
                <h2>Creating A Project</h2>
                <img
                    src={tutorial2}
                    alt="screenshot of project creation page"
                    className={style.screenshot}
                />
                <ul>
                    <li>Use the “+” Icon in the top navigation bar to create a project. This is any personal project you’d like to display from your profile, or a project that is in process you’d like to find collaborators for.</li>
                    <li>Fill out each field with information about your project.</li>
                    <li>If you are unsure about the purpose of a field, hover over the question mark symbol for more information.</li>
                </ul>
                <h2>Exploring Existing Users and Projects</h2>
                <img
                    src={tutorial3}
                    alt="screenshot of search page"
                    className={style.screenshot}
                />
                <ul>
                    <li>Use the search bar at any time to search existing projects. Clicking on the search icon will also take you to the search page.</li>
                    <li>On the search page, you’ll see all projects. You can use the filters to narrow down the search results, or search projects by name with the search bar.</li>
                    <li>You can also switch between searching for users and projects by clicking on the “Users” button or the “Projects” button.</li>
                </ul>
                <h2>Connecting With Users</h2>
                <img
                    src={tutorial4}
                    alt="screenshot of user page"
                    className={style.screenshot}
                />
                <ul>
                    <li>When viewing another user’s page, you can start a chat with that user by clicking on the “Message” button, or invite that user to a project you are part of by clicking “Invite to Project</li>
                    <li>User pages also show the projects that user is affiliated, and you can read more about that project by clicking on the name of the project.</li>
                    <li>You can navigate to your own page by clicking on your profile picture in the top right of the screen. Your own projects are linked from your profile page.</li>
                </ul>
                <h2>Managing Your Projects</h2>
                <img
                    src={tutorial5}
                    alt="screenshot of project page"
                    className={style.screenshot}
                />
                <ul>
                    <li>If you are the owner or administrator of a project, you can have several options for managing that project. You can view and approve join requests from the project page.</li>
                    <li>You can also add a new event by clicking the “Add Event” button. These events will be visible to anyone viewing the project.</li>
                    <li>Clicking on the “Message” button will take you to a group chat with all of the members of the project.</li>
                    <li>If you view a project you are not a member of, you can click on the “Request to Join” button to create a project join request.</li>
                </ul>
            </div>
        </div>
    </>)
}

export default TutorialPage;