import React, { useState, useEffect } from "react";
import api from "../config/AxiosConfig.js";
import { useNavigate, useLocation } from "react-router-dom";
import style from "../styles/modules/SearchPage.module.css";
import FilterMenu from "../components/FilterMenu.js";
import UsersSearchResults from "../components/UsersSearchResults.js";
import ProjectsSearchResults from "../components/ProjectsSearchResults.js";

/**
 * The search will be trigger when:
 * 1. Search Bar Submit
 * 2. Enter Key Pressed
 * 3. Filter Change
 * 4. Search Type Change
 * When any of the above event is triggered, the search page will:
 * 1. Save the changes into formData and store in local storage
 * 2. Send API request to backend and reload the page
 * 3. Restore the saved local storage change
 * @returns Search Page
 */
function SearchPage() {
  const location = useLocation(); //get url
  // const [responseData, setResponse] = useState([]);
  const [userResults, setUserResults] = useState([]);
  const [projectResults, setProjectResults] = useState([]);
  const [images, setImages] = useState({});
  const [searchResultText, setSearchResultText] = useState([]);
  const navigate = useNavigate();

  const [query, setQuery] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData ? JSON.parse(savedFormData).title : "";
  });

  const [searchType, setSearchType] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData).searchType || "projects"
      : "projects";
  });

  const [formData, setFormData] = useState(() => {
    // Load from localStorage on initial render
    const savedFormData = localStorage.getItem("formData");
    console.log("saved form data: ", savedFormData);
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          title: "",
          // tags: [],
          projectTags: [],
          userTags: [],
          hybrid: true,
          inPerson: true,
          virtual: true,
          statusYes: true,
          statusNo: true,
          min: 0,
          max: 100,
          SearchType: "projects",
        };
  });

  //convert the formData to API request format
  const transformFormData = (formData) => {
    const {
      statusYes,
      statusNo,
      hybrid,
      virtual,
      inPerson,
      projectTags,
      ...rest
    } = formData;

    const meetingFormat = [];
    if (hybrid) meetingFormat.push("Hybrid");
    if (virtual) meetingFormat.push("Virtual");
    if (inPerson) meetingFormat.push("In-Person");

    // Map fields to backend format
    return {
      ...rest,
      title: query,
      tags: projectTags,
      statusYes,
      statusNo,
      meetingFormat,
      minMembers: rest.min,
      maxMembers: rest.max,
    };
  };

  const setImageMap = async (imageIds) => {
    if (imageIds.length > 0) {
      const imageResponse = await api.get("/images/batch", {
        params: { imageIds: imageIds.join(",") },
      });
      setImages(imageResponse.data);
    }
  };

  //send API request and update the website
  const fetchData = async (searchType, query, formData) => {
    console.log("fetchData");
    console.log(searchType);
    try {
      const payload = transformFormData({ ...formData, title: query });
      console.log("Submitting with payload:", payload);

      let response;
      let imageIds = [];

      if (searchType === "projects") {
        console.log("project API");
        response = await api.post("public/projects/filter", payload);
        setProjectResults(response.data);
        // imageIds = response.data.map((proj) => proj.imageId).filter(Boolean);
      } else if (searchType === "users") {
        console.log("user API");
        response = await api.post("public/users/filter", formData.userTags, {
          params: { query },
        });
        setUserResults(response.data);
        // imageIds = response.data
        //   .map((user) => user.profilePhotoId)
        //   .filter(Boolean);
      }

      // setResponse(response.data);
      // setImageMap(imageIds);
    } catch (error) {
      console.error(
        `Error fetching data for searchType: ${searchType}, query: ${query}`,
        error
      );
      // alert("network error, please try again");
      //todo: need to find a better way to handle error and notify the user
      throw error; // Rethrow the error for the caller to handle
    }
  };

  //store the formData to localStorage
  useEffect(() => {
    console.log("formData Change");
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const placeholderText =
    searchType === "projects"
      ? "Search for a project"
      : "Search for a peer by name, major, or university";

  useEffect(() => {
    const data = searchType === "projects" ? projectResults : userResults;

    if (!data || data.length === 0) {
      const queryText = formData.title ? ` for "${formData.title}"` : "";
      setSearchResultText(`Sorry, we couldn't find any results ${queryText}`);
    } else {
      const isSingle = data.length === 1;
      const searchTypeText =
        searchType === "projects"
          ? isSingle
            ? "project"
            : "projects"
          : isSingle
          ? "user"
          : "users";
      const queryText = formData.title ? ` for "${formData.title}"` : "";
      setSearchResultText(`${data.length} ${searchTypeText} found${queryText}`);
    }
  }, [userResults, projectResults, searchType, formData.title]);

  useEffect(() => {
    if (query && searchType) {
      console.log("Fetching Data: ", { searchType, query, formData });
      fetchData(searchType, query, formData);
    }
  }, [query, searchType]);

  //------------------------------1. Search Bar Submit-----------------------------
  const getQueryParam = (name) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    console.log("search");
    const queryParam = getQueryParam("query");
    setQuery(queryParam);
    const searchTypeParam = getQueryParam("searchType") || "projects";

    // Set defaults
    setSearchType(searchTypeParam);
    setQuery(queryParam || "");
    setFormData((prev) => ({
      ...prev,
      title: queryParam || "",
      searchType: searchTypeParam,
    }));
  }, [location.search]);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };
  //------------------------------2. Enter Key Pressed-----------------------------
  // document.addEventListener("keydown", function (event) {
  //     if (event.key === "Enter") {
  //       event.preventDefault();
  //       handleEnterPress();
  //     }
  // });

  // function handleEnterPress() {
  //     fetchData(searchType, query, formData);
  // }
  //------------------------------3. Filter Change-----------------------------
  const handleFilterChange = async (updatedFilter) => {
    setFormData(updatedFilter);
  };
  //------------------------------4. Search Type Change-----------------------------
  const handleSearchTypeChange = (type) => {
    setSearchType(type);

    setFormData((prevFormData) => ({
      ...prevFormData,
      searchType: type, // Update only searchType
    }));

    const params = new URLSearchParams(location.search);
    params.set("searchType", type);
    if (query) params.set("query", query);
    navigate(`/search?${params.toString()}`);
  };

  useEffect(() => {
    fetchData(searchType, query, formData);
  }, [searchType, query, formData]);

  const [showFilter, setShowFilter] = useState(false);

  return (
    <>
      <form className={style.searchForm} onSubmit={fetchData}>
        <div className={style.searchFormDisplay}>
          <div className={style.buttonContainer}>
            <button
              type="button"
              className={`${style.toggleButton} ${
                searchType === "projects" ? style.active : ""
              }`}
              onClick={() => {
                if (searchType !== "projects") {
                  setSearchType("projects");
                  handleSearchTypeChange("projects");
                }
              }}
            >
              PROJECTS
            </button>
            <button
              type="button"
              className={`${style.toggleButton} ${
                searchType === "users" ? style.active : ""
              }`}
              onClick={() => {
                if (searchType !== "users") {
                  setSearchType("users");
                  handleSearchTypeChange("users");
                }
              }}
            >
              USERS
            </button>
          </div>
          <h2 className={style.searchResultText}>{searchResultText}</h2>
        </div>
      </form>

      <div className={style.container}>
        {/* Left Sidebar */}
        <div className={style.sidebar}>
          {/* Toggle Button */}
          <button
            className={style.toggleButton} // separate style from filter panel!
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? "Hide Filters ▲" : "Show Filters ▼"}
          </button>

          {/* Filter Panel (conditionally rendered below button) */}
          {showFilter && (
            <div className={style.filter}>
              <FilterMenu
                searchType={searchType}
                formData={formData}
                setFormData={setFormData}
                onFormDataChange={handleFilterChange}
              />
            </div>
          )}
        </div>

        {/* Search Results on the Right */}
        <div className={style.results}>
          {searchResultText ? (
            searchType === "projects" ? (
              <ProjectsSearchResults
                responses={projectResults}
                images={images}
              />
            ) : (
              <UsersSearchResults responses={userResults} images={images} />
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchPage;
