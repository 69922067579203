import style from "../styles/modules/SettingsPage.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../config/AxiosConfig";
import { useTheme } from "../context/ThemeContext";
import { toast } from "react-toastify";
import { useUser } from "../context/UserContext";

function SettingsPage() {
  const { theme, setTheme } = useTheme();
  const { user, setUser } = useUser();
  const userId = useParams().userId;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    hideEmail: false,
    allowEmailCommunications: true,
    themePreference: "system",
  });

  useEffect(() => {
    if (user?.settings) {
      setFormData({
        hideEmail: user.settings.hideEmail ?? false,
        allowEmailCommunications:
          user.settings.allowEmailCommunications ?? true,
        themePreference: user.settings.themePreference ?? "system",
      });
    }
  }, [user]);

  useEffect(() => {
    if (user === null || userId !== user.id) {
      navigate("/search");
      toast.error(
        "Access denied - You do not have permission to change this user's settings."
      );
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value, //handle checkboxes differently
    }));

    if (name === "themePreference") {
      setTheme(value);
    }
  };

  const handleSubmit = async (e) => {
    console.log("submitted");
    e.preventDefault();
    try {
      const response = await api.post(`/users/${user?.id}/settings`, formData);
      if (response.status === 200 || response.status === 201) {
        toast.success("Successfully updated user settings");
      }
    } catch (error) {
      toast.error("Error updating settings:", error);
    }
  };

  return (
    <form className={style.settingsForm} onSubmit={handleSubmit}>
      <div className={style.mainContainer}>
        <h1 className={style.settingsTitle}>User Settings</h1>

        <div className={style.settingContainer}>
          <label className={style.settingTitle} htmlFor="hideEmail">
            Hide email on profile?
          </label>
          <label className={style.switch}>
            <input
              name="hideEmail"
              id="hideEmail"
              type="checkbox"
              checked={formData.hideEmail}
              onChange={handleChange}
            />
            <span className={style.roundSlider}></span>
          </label>
        </div>

        <div className={style.settingContainer}>
          <label
            className={style.settingTitle}
            htmlFor="allowEmailCommunications"
          >
            Allow FindU to send you notifications via email?
          </label>
          <label className={style.switch}>
            <input
              name="allowEmailCommunications"
              id="allowEmailCommunications"
              type="checkbox"
              checked={formData.allowEmailCommunications}
              onChange={handleChange}
            />
            <span className={style.roundSlider}></span>
          </label>
        </div>

        <div className={style.settingContainer}>
          <label className={style.settingTitle} htmlFor="themePreference">
            Site color theme?
          </label>
          <select
            class={style.settingsSelect}
            name="themePreference"
            id="themePreference"
            onChange={handleChange}
            value={formData.themePreference}
          >
            <option value="system">System Default</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>

        <button className={style.submitButton} type="submit">
          Save Changes
        </button>
      </div>
    </form>
  );
}

export default SettingsPage;
