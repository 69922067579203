import style from "../styles/modules/SearchPage.module.css";
import Image from "./Image";
import Tags from "./Tags";
import DescriptionText from "./DescriptionText";
import defaultProjectPhoto from "../assets/default_project_photo.jpeg";
import IconChecks from "../components/IconChecks";
import IconLocation from "../components/IconLocation";
import IconSingleMember from "../components/IconSingleMember";
import IconMultipleMembers from "../components/IconMultipleMembers";

function ProjectPreview({
  title,
  description,
  id,
  image,
  status,
  meetingFormat,
  tags,
  users,
}) {
  const url = "/projects/" + id;
  var numUsers = 0;
  //console.log("image");
  //console.log(image);
  if (users) {
    numUsers = users.length;
  }

  // console.log(document.querySelector("img").src);

  // console.log(`Rendering image for: ${title}, Image URL: ${image}`);

  return (
    <>
      <div class={style.searchResult} id={id}>
        <div class={style.textAndImage}>
          {/* <img src={defualtProjectPhoto} className={style.projectImage} alt="Default Picture" /> */}
          {/* <img src={image} className={`${style.projectImage} ${style.skeleton}`} loading="lazy" alt="" /> */}
          {image ? (
            <Image id={image} styleType={style.projectImage} />
          ) : (
            <img
              src={defaultProjectPhoto}
              className={style.projectImage}
              alt="Default Picture"
            />
          )}
          <div class={style.previewText}>
            <h1>
              <a href={url}> {title} </a>
            </h1>
            <div class={style.detailsRow}>
              {status === true ? (
                <div class={style.status}>
                  <IconChecks />
                  <span>Looking for new members</span>
                </div>
              ) : status === false ? (
                <>
                  <span>Not looking for new members</span>
                </>
              ) : (
                <></>
              )}
              {meetingFormat ? (
                <div>
                  <IconLocation />
                  <span>{meetingFormat} Meetings</span>
                </div>
              ) : (
                <></>
              )}
              {numUsers == 1 ? (
                <div>
                  <IconSingleMember />
                  <span>1 Member</span>
                </div>
              ) : numUsers == 0 ? (
                <></>
              ) : (
                <div>
                  <IconMultipleMembers />
                  <span>{numUsers} Members</span>
                </div>
              )}
            </div>
            <Tags class={style.tags} tags={tags} />
          </div>
        </div>
        <DescriptionText text={description} id={id} />
      </div>
    </>
  );
}
export default ProjectPreview;
