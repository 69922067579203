import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig";
import style from "../styles/modules/ProjectPage.module.css";
import defualtProjectPhoto from "../assets/default_project_photo.jpeg";
import { useParams } from "react-router-dom";
import IconLocation from "../components/IconLocation";

function EventPage() {
  const { eventId } = useParams();
  const [eventInfo, setEventInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddToCalendar = async () => {
    if (!eventId) return;

    setLoading(true);
    setError(null);

    try {
      const response = await api.get(`/events/link/${eventId}`);
      const googleCalendarUrl = response.data;

      console.log(googleCalendarUrl);

      // Redirect to Google Calendar URL
      window.location.href = googleCalendarUrl;
    } catch (err) {
      console.error("Failed to get calendar link:", err);
      setError("Failed to generate calendar link");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    api
      .get(`/events/${eventId}`)
      .then((response) => {
        setEventInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
      });
  }, [eventId]);

  const formatDate = (isoString) => {
    if (!isoString) return "No date set";
    const date = new Date(isoString);
    return date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <>
      <div class={style.projectHeader}>
        <img
          src={defualtProjectPhoto}
          className={style.projectPhoto}
          alt="Default profile photo"
        />
      </div>
      <div class={style.infoSection}>
        <div class={style.nameColumn}>
          <h1 class={style.h1}>{eventInfo.title}</h1>
          <h2>
            <IconLocation /> {eventInfo.location}
          </h2>
        </div>
        <div class={style.contactColumn}>
          <button
            onClick={handleAddToCalendar}
            disabled={loading}
            className={style.calendarButton}
          >
            {loading ? "Loading..." : "Add to Google Calendar"}
          </button>
        </div>
      </div>

      <div class={style.projectPageContent}>
        <div class={style.aboutSection}>
          <div class={style.aboutParagraph}>
            <h3>About Event</h3>
            <p>{eventInfo.description}</p>
          </div>
          {/* <Image id={message.imageId} styleType={style.projectPhoto} /> */}
        </div>
        <div>
          <h3>Start Time</h3>
          <p>Starts: {formatDate(eventInfo.startDateTime)}</p>
        </div>
        <div>
          <h3>End Time</h3>
          <p>Ends: {formatDate(eventInfo.endDateTime)}</p>
        </div>
      </div>
    </>
  );
}

export default EventPage;
