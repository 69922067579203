import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig";
import {useNavigate } from "react-router-dom";
import style from "../styles/modules/MessageButton.module.css";
import Image from "../components/Image";
import Popup from "reactjs-popup";
import { useUser } from "../context/UserContext";
import send from "../assets/SendWhite.png";

function MessageButton({ toId, type = "user" }) {
  const { user, setUser } = useUser();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = async (e) => {
    console.log("sending", toId, message);
    console.log(user);
    var chatId = "none";
    var now = new Date();
    now = now.toISOString();
    try {
      if (type === "project") {
        // chatId = toId;
      } else if (toId && message != "") {
        const chatexists = await api.get(
          `/users/chatexists/${user.id}/${toId}`
        );
        console.log(chatexists);
        if (chatexists.status == 200 && chatexists.data == true) {
          for (const [key, value] of Object.entries(user.chats)) {
            if (value == toId) {
              chatId = key;
              console.log(key, value);
            }
          }
        } else if (chatexists.status == 200) {
          const responseChatId = await api.post(
            `/chats/create/${user.id}/${toId}`,
            now
          );
          console.log(responseChatId);
          if (responseChatId.status === 200 || responseChatId.status === 201) {
            chatId = responseChatId.data;
            console.log("successfully created chat", chatId);
            setMessage("");
          }
        }
      }
      if (chatId != "none") {
        const response = await api.post(
          `/chats/send/${chatId}/${user.id}`,
          {msg: message, ts: now}
        );
        if (response.status == 200 || response.status == 201) {
          console.log("sent message");
          setMessage("");
          navigate(`/chats/${chatId}`);
        }
      }
    } catch (error) {
      console.error("Error sending a message to the chat", error);
    }
  };

  if (user && toId == user.id) {
    return <></>;
  }

  return (
    <>
      {!user ? (
        // Redirect to sign-in if the user isn't signed in
        <button
          className={style.messageButton}
          i
          type="button"
          onClick={() => {
            const currentPath =
              window.location.pathname + window.location.search;
            window.location.href = `/signin?redirect=${encodeURIComponent(
              currentPath
            )}`;
          }}
        >
          Message
        </button>
      ) : type === "project" ? (
        <button
          className={style.messageButton}
          i
          type="button"
          onClick={() => {
            navigate(`/chats/${toId}`);
          }}
        >
          Message
        </button>
      ) : (
        <>
          <Popup
            trigger={
              <button className={style.messageButton} type="button">
                Message
              </button>
            }
            modal
          >
            {(close) => (
              <div className="modal">
                <div className={style.popup}>
                  <div className="content">
                    {/* {type === "project" ? (
                      <p>Send a message to the project's group chat!</p>
                    ) : (
                      <p>Send this user a message!</p>
                    )} */}
                    <p>Send this user a message!</p>
                    <textarea
                      className={style.popupTextInput}
                      name="message"
                      minLength="4"
                      maxLength="480"
                      value={message}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <button
                      className={style.sendButton}
                      onClick={() => {
                        handleSend();
                        close();
                      }}
                    >
                      Send
                      <img
                        src={send}
                        width="20" height="20"
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        </>
      )}
    </>
  );
}

export default MessageButton;
