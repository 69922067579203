import React, { useState, useEffect, useRef } from "react";
import api from "../config/AxiosConfig.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import style from "../styles/modules/ChatPage.module.css";
import Image from "../components/Image";
import Timestamp from "../components/Timestamp";
import send from "../assets/SendWhite.png";

function ChatPreview({fromId, chatId, currentChat, chat}) {
    const [chatTitle, setChatTitle] = useState("");
    const [chatData, setChatData] = useState(chat);
    const [imageId, setImageId] = useState("");
    const [isGroup, setIsGroup] = useState(chat.isProjectGroupchat);
    const [lastMessageText, setLastMessageText] = useState("no messages");
    const [lastMessage, setLastMessage] = useState(chat.lastMessage);
    const [senderName, setSenderName] = useState("");
    var tempTitle = chatTitle;
    var tempImage = imageId;

    useEffect(() => {
        var lastMessageTemp = "no messages"
        if (chat.lastMessage != null) {
            setLastMessageText(chat.lastMessage.content);
            lastMessageTemp = chat.lastMessage.content;
        }
        if (lastMessageTemp[0] == "\"" && lastMessageTemp[lastMessageTemp.length-1] == "\"") {
            setLastMessageText(lastMessageTemp.substring(1, lastMessageTemp.length-1));
            lastMessageTemp = lastMessageTemp.substring(1, lastMessageTemp.length-1);
        }
        if (currentChat && lastMessageTemp.length>60) {
            setLastMessageText(lastMessageTemp.substring(0, 59) + "...");
            lastMessageTemp = lastMessageTemp.substring(0, 59) + "...";
        }
        if (lastMessageTemp.split('\\n').length > 1) {
            setLastMessageText(lastMessageTemp.split('\\n')[0] + "...")
        }

        if (chat.isProjectGroupchat) {
            api.get(`projects/${fromId}`)
                 .then((response) => {
                     if (response.status === 200 || response.status === 201) {
                         setChatTitle(response.data.title + " Members");
                         setImageId(response.data.imageId);
                         tempTitle = response.data.title + " Members";
                         tempImage = response.data.imageId;
                     }
                 })
                 .catch((error) => {
                     console.error("There was an error fetching project", error);
                 });
            if (chat.lastMessage == null) {
                setSenderName("");
            }
            else {
                api.get(`users/${chat.lastMessage.fromId}`)
                    .then((response) => {
                      if (response.status === 200 || response.status === 201) {
                          setSenderName(response.data.name);
                      }
                    })
                    .catch((error) => {
                          console.error("There was an error fetching the user data!", error);
                        });
            }
        }
        else {
           api.get(`users/${fromId}`)
               .then((response) => {
                   if (response.status === 200 || response.status === 201) {
                       setChatTitle(response.data.name);
                       setImageId(response.data.profilePhotoId);
                       tempTitle = response.data.name;
                       tempImage = response.data.profilePhotoId;
                   }
               })
               .catch((error) => {
                   console.error("There was an error fetching user", error);
               })
        }

    }, [chat, window.reactTimestamp]);
    const url = "/chats/" + chatId;

    if (chatTitle=="") {
        return (<></>);
    }

    return (<>
        <a href={url}>
        <div className={style.chatPreviewContainer}>
            {(!currentChat) ? (<>
                {(isGroup) ? (
                    <Image id={tempImage} styleType={style.groupPhotoLarge} />
                ) : (
                    <Image id={tempImage} styleType={style.userPreviewPhotoLarge} />
                )}
            </>) : (<></>)}
            {(chatId == currentChat) ? (<div className={style.currentChat}>.</div>) : (<></>)}
            <div className={style.chatPreview}>
                <div className={style.chatInfo} >
                    {(chat.isProjectGroupchat && currentChat) ? (
                        <Image id={tempImage} styleType={style.groupPhoto} />
                    ) : (currentChat) ? (
                        <Image id={tempImage} styleType={style.userPreviewPhoto} />
                    ) : (<></>)}
                    <h3>{tempTitle}</h3>
                    {(lastMessage != null) ? (
                    <div className={style.previewTime}>
                        {(currentChat) ? (
                            <Timestamp timeGiven={chat.lastMessage.timeSent} displayType={"short"} />
                        ) : (
                            <Timestamp timeGiven={chat.lastMessage.timeSent} displayType={"long"} />
                        )}
                    </div>
                    ) : (<></>)}
                </div>
                {(chat.isProjectGroupchat && senderName != "") ? (
                    <p>{senderName}: {lastMessageText}</p>
                ) : (
                    <p>{lastMessageText}</p>
                )}
            </div>
        </div>
        </a>
    </>)
}

function ChatList({chatMap, currentChat, currentUserId}) {
    var chatIdsTemp = [];
    for (const [key, value] of Object.entries(chatMap)) {
      chatIdsTemp.push(key);
    }
    const [chatIds, setChatIds] = useState(chatIdsTemp);
    const [chats, setChats] = useState([]);

    useEffect(() => {
        api
            .post(`/chats/sort/${currentUserId}`)
            .then((response) => {
                if (response.status === 200) {
//                    console.log(response.data);
                    setChats(response.data);
                }
            })
            .catch((error) => {
               console.error("There was an error fetching the chat previews!", error);
            });
    }, [window.reactTimestamp]);

    if (!currentChat) {
        return (
        <>
            <div className={style.chatListWideContainer}>
               <div className={style.chatListWide}>
               {chats.map((key, i) => (
               <ChatPreview chatId={key.id} fromId={chatMap[key.id]} currentChat={currentChat} chat={key}/>)
               )}
               </div>
            </div>
        </>);
    }
    return(<>
        <div className={style.chatListColumn}>
        {chats.map((key, i) => (
        <ChatPreview chatId={key.id} fromId={chatMap[key.id]} currentChat={currentChat} chat={key}/>)
        )}
        </div>
    </>);
}

function MyMessage({message}) {
    const sent = new Date(Date.parse(message.timeSent));
    var text = message.content;
    if (text == null) {
//        console.log(message);
//        console.log(Date.parse(message.timeSent));
        return (<></>)
    }
    if (text[0] == "\"" && text[text.length-1] == "\"") {
        text = text.substring(1, text.length-1);
    }
    return (<>
        <div className={style.myMessage}>
            <div className={style.myMessageBox}>
                {text.split('\\n').map(e => (<p>{e}</p>))}
            </div>
            <p className={style.timestamp}><Timestamp timeGiven={message.timeSent} displayType={"long"} /></p>
        </div>
    </>);
}

function Message({message, isGroup}) {
    const [sender, setSender] = useState("");
    const sent = new Date(Date.parse(message.timeSent));

    useEffect(() => {
        if (isGroup) {
            api.get(`users/${message.fromId}`)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setSender(response.data);
                    }
                })
                .catch((error) => {
                        console.error("There was an error fetching the user data!", error);
                      });
        }
    }, []);

    var text = message.content;
    if (text[0] == "\"" && text[text.length-1] == "\"") {
        text = text.substring(1, text.length-1);
    }
    return (<>
        <div className={style.message}>
            <div className={style.topContent}>
                {(isGroup) ? (<Image id={sender.profilePhotoId} styleType={style.userPhoto} />) : (<></>)}
                <div className={style.messageBox}>
                    {text.split('\\n').map(e => (<p>{e}</p>))}
                </div>
            </div>
            <div className={style.lowerText}>
                {(isGroup) ? (<p>{sender.name}</p>) : (<></>)}
                <div className={style.timestamp}><Timestamp timeGiven={message.timeSent} displayType={"medium"} /></div>
            </div>
        </div>
    </>);
}

function Chat({chatId, chatMap, currentUserId, deleteChat}) {
    const [chatData, setChatData] = useState([]);
    const [chatMessages, setChatMessages] = useState([])
    const [chatTitle, setChatTitle] = useState("");
    const [url, setUrl] = useState("");
    const [imageId, setImageId] = useState("");
    const [isGroup, setIsGroup] = useState(false);
    useEffect(() => {
        api
            .get(`chats/${chatId}`)
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setChatData(response.data);
                    setChatMessages(response.data.messages);
                    setIsGroup(response.data.isProjectGroupchat);
                    if (response.data.isProjectGroupchat) {
                        api.get(`projects/${chatMap[chatId]}`)
                            .then((response) => {
                                if (response.status === 200 || response.status === 201) {
                                    setChatTitle(response.data.title + " Members");
                                    setUrl("/projects/" + response.data.id);
                                    setImageId(response.data.imageId);
                                }
                            })
                            .catch((error) => {
                                console.error("There was an error fetching data");
                            })
                    }
                    else {
                        api.get(`users/${chatMap[chatId]}`)
                            .then((response) => {
                                if (response.status === 200 || response.status === 201) {
                                    setChatTitle(response.data.name);
                                    setUrl("/users/" + response.data.id);
                                    setImageId(response.data.profilePhotoId);
                                }
                            })
                            .catch((error) => {
                                console.error("There was an error fetching the chat data!", error);
                            })
                    }
                }
            })
            .catch((error) => {
                console.error("There was an error fetching the chat data!", error);
            });

    }, [window.reactTimestamp]);

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages])

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (<>
        <div className={style.chatHeader}>
            <a
                href={`/chats`}
                className={style.backButton}
            >
                <svg width="25" height="25" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 2.5L3 16M3 16L17 29.5M3 16H36" stroke="currentColor" stroke-width="4" stroke-linecap="round"/>
                </svg>
            </a>
            <div className={style.chatName}>
            {(isGroup) ? (
                <Image id={imageId} styleType={style.groupPhotoMedium} />
            ) : (
                <Image id={imageId} styleType={style.userPreviewPhotoMedium} />
            )}
            <a href={url}><h1>{chatTitle}</h1></a>
            </div>
            <div>
            {(chatData.isProjectGroupchat) ? (<></>) : (
            <button
                className={style.deleteButton}
                onClick={deleteChat}
                type="button"
            >Delete Chat</button>)
            }
            </div>
        </div>
        <div className={style.messageContainer}>
            {chatMessages.map((message, i) => ( <>
                {(message.fromId == currentUserId) ?
                (<MyMessage message={message} />)
                 : (<Message message={message} isGroup={chatData.isProjectGroupchat} />)}
            </>))}
            <div ref={messagesEndRef} />
        </div>
    </>);
}

function MessageForm( {handleSendMessage, messageText, setMessageText} ) {
    const handleInputChange = (e) => {
        setMessageText(e.target.value);
    }

    return (<div className={style.messageInputArea}>
        <textarea
            className={style.messageInput}
            name="newMessage"
            minLength="4"
            maxLength="480"
            value={messageText}
            onChange={handleInputChange}
        />
        <button
            className={style.sendButton}
            onClick={handleSendMessage}
            type="button"
        >
            Send
            <img
                src={send}
                width="20" height="20"
            />
        </button>
    </ div>)
}

function ChatPage({}) {
    const [currentUser, setCurrentUser] = useState(null);
    const [chatMap, setChatMap] = useState([]);
    const [messageText, setMessageText] = useState("");
    const {chatId} = useParams();
    const navigate = useNavigate();

//    const [chatSent, setChatSent] = useState(Date.now()); // was using this for chatlist reload, not using currently

    useEffect(() => {
        api
          .get("public/auth/getCurrentUser", { withCredentials: true })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setCurrentUser(response.data);
              if (response.data.chats == null) {
              }
              else {
                  setChatMap(response.data.chats);

              }
            }
          })
          .catch((error) => {
            console.error("There was an error fetching the current user!", error);
          });

      }, []);

    const handleSendMessage = async (e) => {
        try {
            var toId = chatMap[chatId];
            if (toId && (messageText != "")) {
                var now = new Date();
                now = now.toISOString();
                const response = await api.post(`/chats/send/${chatId}/${currentUser.id}`, {msg: messageText, ts: now});
                if (response.status ===200 || response.status === 201) {
                    setMessageText("");
//                    setChatSent(Date.now()); // was using this for chatlist reload, not using currently
                    window.reactTimestamp = Date.now();
                }
            }
        } catch (error) {
            console.error(
                "Error sending a message to the chat", error
            )
        }
    }

    const deleteChat =  async (e) => {
        try {
            const response = await api.post(`/chats/delete/${chatId}`);
            if (response.status ===200 || response.status === 201) {
                navigate(`/chats`);
                window.location.reload();
            }
        } catch (error) {
            console.error(
                "Error deleting chat", error
            )
        }
    }

    return (<>
        {(chatMap.length == 0) ? (<p>You have no chats</p>) : (<>
        <div className={style.chatPage}>
            <ChatList chatMap={chatMap} currentChat={chatId} currentUserId={currentUser.id} />
            {(!chatId) ? (
                <></>
            ) :
                (<>
                    <div className={style.chatColumn}>
                        <Chat chatId={chatId}
                            chatMap={chatMap}
                            currentUserId={currentUser.id}
                            deleteChat={deleteChat}
                            />
                        <MessageForm
                            handleSendMessage={handleSendMessage}
                            messageText={messageText}
                            setMessageText={setMessageText}
                        />
                    </div>
                </>)
            }
        </div>
        </>)}
    </>);
}

export default ChatPage;