import system from "../assets/SystemDesignDiagram.jpeg";
import style from "../styles/modules/AboutPage.module.css";
import logo from "../assets/LogoWord.png";
import logoDark from "../assets/FindULogoDarkMode.png";
import { useTheme } from "../context/ThemeContext";

function AboutPage({}) {
  const { theme, setTheme } = useTheme();
    return (<div className={style.pageContainer}>
        <div className={style.aboutUs}>
            <picture>
              {theme === "system" ? (
                <>
                  <source srcset={logo} media="(prefers-color-scheme: light)" />
                  <source
                    srcset={logoDark}
                    media="(prefers-color-scheme: dark)"
                  />
                  <img
                    src={logo}
                    alt="logo link to home"
                    className={style.image}
                  />
                </>
              ) : theme === "dark" ? (
                <>
                  <source
                    srcset={logoDark}
                    media="(prefers-color-scheme: dark)"
                  />
                  <img
                    src={logo}
                    alt="logo link to home"
                    className={style.image}
                  />
                </>
              ) : (
                <>
                  <source srcset={logo} media="(prefers-color-scheme: dark)" />
                  <img
                    src={logo}
                    alt="logo link to home"
                    className={style.image}
                  />
                </>
              )}
            </picture>
            <h1>About Us</h1>
            <p>
                Imagine a Business Entrepreneurship student who has a great idea for a business they want to start. They need a website to reach potential customers and they don’t have the skills to make one on their own. Across campus, there could be a Computer Science student who needs a personal project to add to their resumes, but doesn’t have any ideas they’re excited about. These two students could help each other, and FindU is designed to let them do just that.
            </p>
            <p>
                FindU is a website designed to help University of Utah students find each other in order to collaborate on personal projects. This platform provides students a way to advertise themselves and their ideas to their peers. Students can also explore existing projects and users in order to find people and ideas they are excited about. Students can make connections through our chat system, by requesting to join a project, or by inviting a user to join a project. Finally, we provide project management tools in the form of project-wide group chats and project events.
            </p>
            <img
                src={system}
                alt="system diagram"
                className={style.image}
            />
            <p>
                This website utilizes a React based frontend. The frontend communicates with the backend through Axios API requests. Our backend uses a Java Spring framework with our database hosted on mongoDB. The backend and frontend are hosted on AWS.
            </p>
            <p><a href="/tutorial">Learn how to use FindU</a></p>
            <p><a href="/team"> Read more about our team</a></p>
        </div>
    </div>)
}

export default AboutPage;