import React, { useEffect, useState } from "react";
import style from "../styles/modules/LandingPage.module.css";
import api from "../config/AxiosConfig.js";
import Image from "../components/Image";
import IconHuman from "./IconHuman.js";
import IconHumanChecks from "./IconHumanChecks.js";
import defaultProjectPhoto from "../assets/default_project_photo.jpeg";

function ProjectCard({ id, showJoined }) {
  const [projectInfo, setProjectInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/projects/${id}`)
      .then((response) => {
        setProjectInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the project data!", error);
      });
  }, []);
  const url = "/projects/" + id;
  var numMembers = 0;
  if (projectInfo.usersId) {
    numMembers = projectInfo.usersId.length;
  }
  let title = "";
  if (projectInfo.title && projectInfo.title.length > 35) {
    title = projectInfo.title.substring(0, 35) + "...";
  } else {
    title = projectInfo.title || "";
  }
  let description = "";
  if (projectInfo.description && projectInfo.description.length > 100) {
    description = projectInfo.description.substring(0, 100) + "...";
  } else {
    description = projectInfo.description || "";
  }

  return (
    <>
      <div class={style.previewCard}>
        <div>
          {projectInfo.imageId ? (
            <Image id={projectInfo.imageId} styleType={style.projectImage} />
          ) : (
            <img
              src={defaultProjectPhoto}
              className={style.projectImage}
              alt="Default Picture"
            />
          )}
        </div>
        <div class={style.previewText}>
          <h3>
            <a href={url}> {title} </a>
          </h3>
          <IconHuman />
          {numMembers} member{numMembers > 1 ? <>s</> : <></>}
          {showJoined ? (
            <div class={style.status}>
              <IconHumanChecks />
              <p>Joined</p>
            </div>
          ) : (
            <p>{description}</p>
          )}
        </div>
      </div>
      <div></div>
    </>
  );
}
export default ProjectCard;
