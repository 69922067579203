import style from "../styles/modules/AboutPage.module.css";
import syd from "../assets/syd.jpg";
import landen from "../assets/Landen.jpeg";
import renee from "../assets/Renee.jpeg";
import amelia from "../assets/Amelia.jpg";

function TeamMembersPage({}) {
    return (<div className={style.pageContainer}>
        <div className={style.teamMembers}>
            <h1> Team Members </h1>
            <div className={style.bioSection}>
                <img
                    src={amelia}
                    alt="system diagram"
                    className={style.headshot}
                />
                <div>
                    <h2>Amelia Johnson</h2>
                    <p>
                        Hi! I’m Amelia. I’m graduating in the Spring of 2025 with a degree in Computer Science from the
                        University of Utah–and I’m also one of the co-founders of FindU. I’m really interested in
                        Compilers and Programming Language Design; in my spare time I like to work on personal projects
                        revolving around these subjects. My current favorite is called cpporth, and it’s an interpreter
                        written in C++ for a stack-based concatenative language called Porth. I’m currently looking for
                        a job in the Computer Science field, and I’d love to show off my work. I’ve lived in Utah my
                        whole life and I’m excited by the opportunity here. Besides programming, in my free time I enjoy
                        reading, watching movies, working out, seeing my friends, and learning new things. If you’re
                        interested in contacting me, or would like to see more of my work, you can find me on LinkedIn
                        at <a href="https://www.linkedin.com/in/amelia-johnson-0511b6331/">https://www.linkedin.com/in/amelia-johnson-0511b6331/</a>
                        or on GitHub at <a href="https://github.com/amelia-online">https://github.com/amelia-online</a>
                    </p>
                </div>
            </div>
            <div className={style.bioSection}>
                <div>
                    <h2>Landen Hughes</h2>
                    <p>
                        Hey everyone! I’m Landen, one of the co-founders and developers of FindU! When we first started
                        FindU, I was primarily a front-end developer, but these days I tend to work the full stack. I am
                        originally from Coeur d’Alene, Idaho, but I have loved studying at the U both here in Salt Lake
                        City and at the asia campus in Incheon, South Korea! Currently, I’m getting ready to graduate
                        from the U with a bachelor’s degree in computer science. I don’t yet have a job lined up for
                        after graduation, but I am hoping to relocate somewhere new! Outside of school, I love spending
                        time outdoors, at the gym, or at home reading and playing video games. I hope you enjoy FindU!
                        If you have any questions, comments, or concerns (or just want to be friends), send me an email
                        at <a href="mailto:landenhughes21@gmail.com">landenhughes21@gmail.com</a>! You can also feel free to connect with me on LinkedIn
                        at <a href="https://www.linkedin.com/in/landen-hughes/">https://www.linkedin.com/in/landen-hughes/</a>.
                    </p>
                </div>
                <img
                    src={landen}
                    alt="system diagram"
                    className={style.headshot}
                />
            </div>
            <div className={style.bioSection}>
                <img
                    src={renee}
                    alt="system diagram"
                    className={style.headshot}
                />
                <div>
                    <h2>Renee Tong</h2>
                    <p>
                        Hi, I am Renee, one of the co-founders/developers of FindU. I am majoring in Computer Science
                        and minoring in Business at University of Utah. I am from China. This May, I will be graduating
                        with a BS degree. And I will start working as a software engineer at Goldman Sachs. I will be
                        staying in Salt Lake City, which will be exciting since I have all my friends here. I am looking
                        forward to building more memories here. In the field of computer science, I am interested in web
                        application development, I was working on full stack for this project. Recently, I have been
                        very interested in Machine Learning, I plan to learn more in the future as I discover it would
                        help build this website. In my free time,  I like houseplants, drawing and reading. Lastly, I
                        hope everyone enjoys the website and finds it helpful, feel free to reach out for any anything!
                    </p>
                    <p>
                        Contact: <a href="mailto:reneetong1201@gmail.com">reneetong1201@gmail.com</a>
                    </p>
                </div>
            </div>
            <div className={style.bioSection}>
                <div>
                    <h2>Sydney Andrus</h2>
                    <p>
                        I’m Sydney Andrus, graduating with a Bachelor’s in Computer Science after this term. After
                        I graduate I’ll be moving to Madison, Wisconsin to work as a software developer for the healthcare
                        software company EPIC Systems. I’ve been working on FindU as our capstone project, largely on the
                        front-end and on UI design and styling. I’m very interested in human-computer interaction, which
                        keeps me on the front-end, but I have plenty of full stack experience. I’m originally from
                        Beaverton, Oregon, but I’ve loved my time here in Salt Lake at the University of Utah. I’ve spent my
                        last five semesters at the U working as a Teaching Assistant for CS 3810: Software Organization.
                        When I have free time, I love listening to old records and quilting. If you’d like to keep up to
                        date with me, you can reach me at <a href="mailto:sydney.andrus.or@gmail.com">sydney.andrus.or@gmail.com</a>.
                        You can also check out my LinkedIn
                        page here: <a href="https://www.linkedin.com/in/sydneyandrus0/">https://www.linkedin.com/in/sydneyandrus0/</a>
                        .
                    </p>
                </div>
                <img
                    src={syd}
                    alt="system diagram"
                    className={style.headshot}
                />
            </div>
        </div>
    </div>)
}

export default TeamMembersPage;