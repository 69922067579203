import React from "react";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function MyDateTimePicker({ value, onChange, label = "choose event time" }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker
        label={label}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => {
          if (newValue) {
            onChange(newValue.toISOString());
          } else {
            onChange("");
          }
        }}
        format="MM/DD/YYYY hh:mm a"
        viewRenderers={{
          hours: null,
          minutes: null,
          seconds: null,
        }}
        slotProps={{
          textField: {
            sx: {
              marginTop: 1,
              "& input": {
                color: "var(--text)",
              },
              "& .MuiInputLabel-root": {
                color: "var(--text)",
              },
              "& label.Mui-focused": {
                color: "var(--text)",
              },
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--outline)",
                },
              },
              "& .MuiSvgIcon-root": {
                color: "var(--text)",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default MyDateTimePicker;
