import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import style from "../styles/modules/ProjectPage.module.css";
import Image from "../components/Image";
import EditProjectPage from "../pages/EditProjectPage";
import Popup from "reactjs-popup";
import { useUser } from "../context/UserContext";
import defaultProfilePhoto from "../assets/default_profile_photo.jpg";
import defualtProjectPhoto from "../assets/default_project_photo.jpeg";
import MessageButton from "../components/MessageButton";
import EventCard from "../components/EventCard";
import ImageGallery from "../components/ImageGallery";

function MemberCard({ id, title }) {
  const [memberInfo, setMemberInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/users/${id}`)
      .then((response) => {
        setMemberInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
      });
  }, [id]);
  const url = "/users/" + id;
  // use below fillerId to check profile picture styling
  // const fillerId = "674e48741c6c1201f29a7e67";
  if (!title || typeof title == undefined) {
    title = "Project Member";
  }
  return (
    <div class={style.memberCard}>
      <a href={url}>{memberInfo.name} </a>
      <div class={style.memberCardInfo}>
        {/* <Image
          id={memberInfo.profilePhotoId}
          styleType={style.profilePicture}
        /> */}
        {memberInfo.profilePhotoId ? (
          <Image
            id={memberInfo.profilePhotoId}
            styleType={style.profilePicture}
          />
        ) : (
          <img
            src={defaultProfilePhoto}
            className={style.profilePicture}
            alt="Default Profile"
          />
        )}
        <div class={style.memberDetails}>
          <p>{title}</p>
          <h4>{memberInfo.major}</h4>
        </div>
      </div>
    </div>
  );
}

function Members({ usersId, userTitles }) {
  if (!usersId || typeof usersId == "undefined" || usersId.length === 0) {
    return (
      <>
        <p>This project does not currently have any members.</p>
      </>
    );
  }
  return (
    <div class={style.memberCards}>
      {usersId.map((id, i) => (
        <MemberCard id={id} title={userTitles[id]} />
      ))}
    </div>
  );
}

function Events({ eventIds }) {
  if (!eventIds || typeof eventIds == "undefined" || eventIds.length === 0) {
    return (
      <>
        <p>This project does not currently have any events.</p>
      </>
    );
  }
  return (
    <div class={style.memberCards}>
      {eventIds.map((id, i) => (
        <EventCard id={id} />
      ))}
    </div>
  );
}

function ProjectTags({ tags }) {
  if (typeof tags == "undefined") {
    return <></>;
  }
  return (
    <div class={style.tags}>
      {tags.map((tag, i) => (
        <div class={style.tag} key={i}>
          {" "}
          {tag}
        </div>
      ))}
    </div>
  );
}

function JoinPopup({
  HandleJoin,
  userRole,
  isMember,
  HandleLeave,
  joinMessage,
  setJoinMessage,
  hasRequested,
}) {
  const [isJoinButtonHovered, setIsJoinButtonHovered] = useState(false);
  const { user, setUser } = useUser();

  const handleInputChange = (e) => {
    setJoinMessage(e.target.value);
  };
  const handleSubmit = () => {
    HandleJoin();
  };
  const isUserProjectOwner = () => {};
  return (
    <>
      {!isMember ? (
        <>
          {!user ? (
            // Redirect to sign-in if the user isn't signed in
            <button
              className={style.joinButton}
              type="button"
              onClick={() => {
                const currentPath =
                  window.location.pathname + window.location.search;
                window.location.href = `/signin?redirect=${encodeURIComponent(
                  currentPath
                )}`;
              }}
            >
              Request to Join
            </button>
          ) : (
            <>
              {hasRequested ? (
                <button
                  className={style.joinButton}
                  type="button"
                  disabled={true}
                >
                  Request Sent
                </button>
              ) : (
                // Show the popup if the user is signed in
                <Popup
                  trigger={
                    <button className={style.joinButton} type="button">
                      Request to Join
                    </button>
                  }
                  modal
                >
                  {(close) => (
                    <div className="modal">
                      <div className={style.popup}>
                        <div className="content">
                          <p>
                            Let the project admin know why you'll be a great fit
                            for this project!
                          </p>
                          <textarea
                            className={style.popupTextInput}
                            name="requestMessage"
                            minLength="4"
                            maxLength="250"
                            value={joinMessage}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div>
                          <button
                            className={style.sendRequestButton}
                            onClick={() => {
                              handleSubmit();
                              close();
                            }}
                          >
                            Send Request
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
              )}
            </>
          )}
        </>
      ) : userRole !== "Owner" ? (
        <button
          className={`${style.joinButton} ${style.joined}`}
          onClick={HandleLeave}
          type="button"
          onMouseEnter={() => setIsJoinButtonHovered(true)}
          onMouseLeave={() => setIsJoinButtonHovered(false)}
        >
          {isJoinButtonHovered ? "Leave Project?" : "Joined"}
        </button>
      ) : (
        <></>
      )}
    </>
  );
}

function JoinRequestCard({
  userId,
  image,
  message,
  handleAccept,
  handleReject,
}) {
  const [userInfo, setUserInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/users/${userId}`)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
      });
  }, [userId]);
  const url = "/users/" + userId;
  console.log(userInfo);
  return (
    <>
      <div class={style.requestCard}>
        {/* <Image id={userInfo.profilePhotoId} styleType={style.profilePicture}/> */}
        {userInfo.profilePhotoId ? (
          <Image
            id={userInfo.profilePhotoId}
            styleType={style.profilePicture}
          />
        ) : (
          <img
            src={defaultProfilePhoto}
            className={style.profilePicture}
            alt="Default Profile"
          />
        )}
        <div class={style.memberDetails}>
          <a href={url}>{userInfo.name} </a>
          <p>{message}</p>
        </div>
        <button id={userId} type="button" onClick={handleAccept}>
          Accept
        </button>
        <button id={userId} type="button" onClick={handleReject}>
          Reject
        </button>
      </div>
    </>
  );
}

function JoinRequests({ joinRequests, handleAccept, handleReject, images }) {
  // console.log(joinRequests)
  if (
    !joinRequests ||
    typeof joinRequests == undefined ||
    joinRequests.length === 0
  ) {
    return <></>;
  }
  return (
    <div>
      <h3>Join Requests</h3>
      <div class={style.joinRequests}>
        {joinRequests.map((request, i) => (
          <JoinRequestCard
            userId={request.userId}
            image={images[request.profilePhotoId]}
            message={request.message}
            handleAccept={handleAccept}
            handleReject={handleReject}
          />
        ))}
      </div>
    </div>
  );
}

function ProjectPage() {
  const { projectId } = useParams();
  const [message, setMessage] = useState([]);
  // const [imageData, setImageData] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { user, setUser } = useUser();
  const [joinMessage, setJoinMessage] = useState("");
  const [hasRequested, setHasRequested] = useState(false);
  const [userRole, setUserRole] = useState("Unenrolled");
  const [images, setImages] = useState({});

  var tags = [];
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Fetch data from the backend
    //get query param from url
    const getQueryParam = (name) => {
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get(name);
    };

    api
      .get(`/projects/${projectId}`)
      .then((response) => {
        setMessage(response.data);
        console.debug("user data: ", user);
        if (
          response.data.usersId &&
          user &&
          response.data.usersId.includes(user.id)
        ) {
          setIsMember(true);
          console.debug("USER IS SIGNED IN AND IS A USER");
          return api.get(`users/${user.id}/role/${projectId}`, {
            withCredentials: true,
          });
        } else {
          console.debug("USER IS NOT SIGNED IN OR IS NOT A MEMBER");
        }
      })
      .then((response) => {
        if (response) {
          console.log("USER ROLE: ", response.data);
          setUserRole(response.data);
        }
        api.get(`users/requested/${user.id}/${projectId}`).then((response) => {
          console.log(response.data);
          if (response.data) {
            setHasRequested(true);
          }
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
        setIsMember(false);
      });
  }, [projectId, user, location]);

  //make sure to check if the current user is a member correcly
  useEffect(() => {
    if (user && message.usersId && message.usersId.includes(user.id)) {
      setIsMember(true);
    } else {
      setIsMember(false);
    }
  }, [user, message]);

  if (editMode) {
    //        console.log("returning edit project page");
    return (
      <>
        <EditProjectPage projectData={message} />
      </>
    );
  }

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const HandleJoin = async (e) => {
    let finalMessage = joinMessage;

    if (finalMessage.trim() === "") {
      finalMessage = "I would be a great fit for this project!";
      setJoinMessage(finalMessage);
    }
    try {
      var now = new Date();
      now = now.toISOString();
      const projResponse = await api.post(
        `/users/join/${user.id}/${projectId}`,
        { msg: finalMessage, ts: now }
      );
      if (projResponse.status === 200 || projResponse.status === 201) {
        console.log("Successfully requested to join the project");
        setHasRequested(true);
        setMessage("");
        window.location.reload();
      }
    } catch (error) {
      console.error(
        "Error adding user to the project or project to the user's list:",
        error
      );
    }
  };

  const HandleLeave = async (e) => {
    try {
      const response = await api.delete(
        `/users/${user.id}/deleteProject/${projectId}`
      );

      if (response.status === 200 || response.status === 201) {
        console.log("Successfully removed user from the project");
        setIsMember(false);

        // Remove the userId from the project members list
        setMessage((prevMessage) => {
          const updatedUsersId = prevMessage.usersId.filter(
            (id) => id !== user.id
          );
          return {
            ...prevMessage,
            usersId: updatedUsersId,
          };
        });
      }
    } catch (error) {
      console.error(
        "Error adding user to the project or project to the user's list:",
        error
      );
    }
  };

  const HandleEdit = (e) => {
    navigate(`/projects/${projectId}/edit`);
  };

  const handleAddEvent = (e) => {
    navigate(`/projects/${projectId}/addEvent`);
  };

  const handleAccept = async (e) => {
    try {
      const newUserId = e.target.id;
      console.log(e);
      console.log("tony's id:", user.id);
      var now = new Date();
      now = now.toISOString();
      const projResponse = await api.post(
        `/users/accept/${user.id}/${newUserId}/${projectId}`,
        now
      );
      if (projResponse.status === 200 || projResponse.status === 201) {
        console.log("Successfully added user to the project");
        window.location.reload();
      }
    } catch (error) {
      console.error(
        "Error adding user to the project or project to the user's list:",
        error
      );
    }
  };

  const handleReject = async (e) => {
    try {
      const newUserId = e.target.id;
      console.log(e);
      var now = new Date();
      now = now.toISOString();
      const projResponse = await api.post(
        `/users/deny/${user.id}/${newUserId}/${projectId}`,
        now
      );
      if (projResponse.status === 200 || projResponse.status === 201) {
        console.log("Successfully rejected user from project");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error rejecting project join request", error);
    }
  };

  return (
    <>
      <div class={style.projectHeader}>
        {message.imageId ? (
          <Image id={message.imageId} styleType={style.projectPhoto} />
        ) : (
          <img
            src={defualtProjectPhoto}
            className={style.projectPhoto}
            alt="Default profile photo"
          />
        )}
      </div>
      <div class={style.infoSection}>
        <div class={style.nameColumn}>
          <h1 class={style.h1}>{message.title}</h1>
          <div class={style.nameColumnLower}>
            <div class={style.status}>
              {message.status === false ? (
                <h3 class={style.h3}>Not looking for members</h3>
              ) : (
                <h3 class={style.h3}>Looking for members</h3>
              )}
            </div>
            {userRole == "Owner" || userRole == "Admin" ? (
              <div className={style.adminButtons}>
                <button
                  className={style.editButton}
                  onClick={HandleEdit}
                  type="button"
                >
                  {" "}
                  Edit Project{" "}
                </button>
                <button
                  className={style.editButton}
                  onClick={handleAddEvent}
                  type="button"
                >
                  Add Event
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div class={style.contactColumn}>
          <JoinPopup
            isMember={isMember}
            userRole={userRole}
            HandleJoin={HandleJoin}
            HandleLeave={HandleLeave}
            joinMessage={joinMessage}
            setJoinMessage={setJoinMessage}
            hasRequested={hasRequested}
          />
          {isMember ? (
            <MessageButton toId={message.chatId} type={"project"} />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div class={style.projectPageContent}>
        {userRole == "Owner" || userRole == "Admin" ? (
          <JoinRequests
            joinRequests={message.joinRequests}
            handleAccept={handleAccept}
            handleReject={handleReject}
            images={images}
          />
        ) : (
          <></>
        )}
        <div>
          { (message.imageGalleryList && message.imageGalleryList.length > 0) ? (<>
          <h3>Image Gallery</h3>
          <ImageGallery imageIds={message.imageGalleryList} />
          </>) : (<></>) }
        </div>
        <div>
          <h3>Members</h3>
          <Members usersId={message.usersId} userTitles={message.userTitles} />
        </div>
        <div class={style.aboutSection}>
          <div class={style.aboutParagraph}>
            <h3>About Us</h3>
            <p>{message.description}</p>
          </div>
          {/* <Image id={message.imageId} styleType={style.projectPhoto} /> */}
        </div>
        <div>
          <h3>Project Tags</h3>
          <ProjectTags tags={message.tags} />
        </div>
        <div>
          <h3>Meeting Format</h3>
          <div class={style.tag}> {message.meetingFormat} </div>
        </div>
        <div>
          <h3>Events</h3>
          <Events eventIds={message.eventIds} />
        </div>
      </div>
    </>
  );
}

export default ProjectPage;
