import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig";

function Image({ id, styleType }) {
  const [imageData, setImageData] = useState(null);
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    if (id) {
      api
        .get(`/images/${id}`, {
          responseType: "arraybuffer",
        })
        .then((imageResponse) => {
          const base64Image = arrayBufferToBase64(imageResponse.data);
          setImageData(`data:image/jpeg;base64,${base64Image}`);
          //            console.log(imageData);
        })
        .catch((imageError) => {
          console.error(
            "There was an error fetching the image data!",
            imageError
          );
        });
    }
  }, [id, styleType]);

  if (!id) {
    //        console.error('No image found in response headers');
    return <></>;
  }
  return (
    <img loading="lazy" class={styleType} src={imageData} alt="Project image" />
  );
}

export default Image;
