import React, { useState, useEffect } from "react";
import api from "../config/AxiosConfig";
import { useNavigate } from "react-router-dom";
import style from "../styles/modules/CreationForms.module.css";
import CharacterCounterInput from "../components/CharacterCounterInput";
import { toast } from "react-toastify";
import TagSelector from "../components/TagSelector";
import ToolTip from "../components/ToolTip";

function CreateProjectPage() {
  //state to hold form data
  const [formData, setFormData] = useState({
    ownerId: "",
    title: "",
    description: "",
    meetingFormat: [],
    tags: [],
    status: null,
    usersId: [],
    userRoles: {},
    userTitles: {},
    joinRequests: [],
    imageId: null,
    teamSize: 1,
  });
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("public/auth/getCurrentUser", {
          withCredentials: true,
        });
        if (response.status === 200) {
          const userid = response.data.id;
          setUser(response.data);
          setFormData((prevData) => ({
            ...prevData,
            ownerId: userid,
            usersId: [userid],
          }));
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        navigate("/signin");
      }
    };
    fetchUser();
  }, [navigate]);

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;

    // If it's the image field, handle the image upload separately
    if (name === "image" && files && files[0]) {
      setFormData((prevData) => ({
        ...prevData,
        imageId: files[0],
      }));
    } else {
      if (name === "status") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value === "true", // Convert the value to boolean
        }));
      } else if (name === "meetingFormat") {
        console.log(value);
        setFormData((prevData) => ({
          ...prevData,
          [name]: [value],
        }));
        console.log(formData);
      } else {
        // Handle regular input changes
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); //prevent page reload
    // if (!formData.imageId) {
    //   alert(
    //     "Please upload a profile picture. Don't worry, you can always change it later!"
    //   );
    //   return;
    // }

    // Create FormData for image upload
    if (formData.imageId !== null) {
      const formDataImage = new FormData();
      formDataImage.append("file", formData.imageId);
      api
        .post("/images/upload", formDataImage, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((imageUploadResponse) => {
          if (
            imageUploadResponse.status === 200 ||
            imageUploadResponse.status === 201
          ) {
            console.log(
              "Image uploaded successfully:",
              imageUploadResponse.data
            );

            // Directly include the image ID in the form data for the project update
            const updatedFormData = {
              ...formData,
              imageId: imageUploadResponse.data,
            };

            // Now, send the profile update request with the updated form data
            return api.post("/projects", updatedFormData, {
              withCredentials: true,
            });
          } else {
            throw new Error("Image upload failed.");
          }
        })
        .then((response) => {
          // Handle successful project creation
          if (response.status === 200 || response.status === 201) {
            toast.success("Project created successfully!");
            console.log("Successfully created project");
            console.log("Got this for project id: ", response.data);
            user.projectsId.push(response.data);
            console.log("Updated user info: ", user);
            api.put(`/users/${user.id}`, user, {
              withCredentials: true,
            });
            navigate(`/projects/${response.data}`);
          }
        })
        .catch((error) => {
          // Handle errors from either image upload or profile update
          console.error("Error:", error);
          alert("Error creating project, please try again.");
          // navigate("/createproject");
        });
    } else {
      api
        .post("/projects", formData, {
          withCredentials: true,
        })
        .then((response) => {
          // Handle successful project creation
          if (response.status === 200 || response.status === 201) {
            toast.success("Project created successfully!");
            console.log("Successfully created project");
            console.log("Got this for project id: ", response.data);
            user.projectsId.push(response.data);
            console.log("Updated user info: ", user);
            api.put(`/users/${user.id}`, user, {
              withCredentials: true,
            });
            navigate(`/projects/${response.data}`);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Error creating project, please try again.");
        });
    }
  };
  return (
    <>
      <div class={style.container}>
        <div>
          <h1 class={style.title}>
            Create A New Project
            <ToolTip
              message={
                "Fill in the fields below to create your new project! Don't worry, all information and project settings can be changed later."
              }
            />
          </h1>
          <form
            class={style.formBody}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div class={style.entryField}>
              <label className={style.fieldLabel} htmlFor="title">
                Project Title:{" "}
                <ToolTip
                  message={"Give your new project a name. How exciting!"}
                />
              </label>
              <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="title"
                id="title"
                required
                maxChars={50}
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>
            <div class={style.entryField}>
              <label className={style.fieldLabel} htmlFor="description">
                Project Description:{" "}
                <ToolTip
                  message={
                    "What should others know about your project? What do you want to do and how do you want to do it?"
                  }
                />
              </label>
              <CharacterCounterInput
                type="textarea"
                className={style.largeTextEntry}
                name="description"
                id="description"
                required
                maxChars={2000}
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
            <div class={style.entryField}>
              <TagSelector
                formData={formData}
                setFormData={setFormData}
                formDataField={"tags"}
                toolTipMsg={
                  "Much like skill tags on user profiles, project tags help users quickly find projects that they would be a good fit for.\n Example: A project that is creating an app may include tags like 'Mobile App Development' and 'Software'."
                }
              />
            </div>
            <div class={style.entryField}>
              <label class={style.fieldLabel} for="meetingFormat">
                Meeting Format:
                <ToolTip
                  message={
                    "How do you want to meet with your future team? If you're not sure, we recommend selecting 'Hybrid'."
                  }
                />
              </label>
              <div class={style.hspanElements}>
                <label>
                  <input
                    type="radio"
                    name="meetingFormat"
                    value="In-Person"
                    required
                    onChange={handleInputChange}
                  />
                  In-Person
                </label>
                <label>
                  <input
                    type="radio"
                    name="meetingFormat"
                    value="Virtual"
                    required
                    onChange={handleInputChange}
                  />
                  Virtual
                </label>
                <label>
                  <input
                    type="radio"
                    name="meetingFormat"
                    value="Hybrid"
                    required
                    onChange={handleInputChange}
                  />
                  Hybrid
                </label>
              </div>
            </div>
            <div class={style.entryField}>
              <label class={style.fieldLabel} for="status">
                Recruiting Status:
                <ToolTip
                  message={
                    "Select 'Looking for new members' if you don't already have a full team in mind."
                  }
                />
              </label>
              <div class={style.hspanElements}>
                <label>
                  <input
                    type="radio"
                    name="status"
                    value={true}
                    required
                    checked={formData.status === true}
                    onChange={handleInputChange}
                  />
                  Looking for new members
                </label>
                <label>
                  <input
                    type="radio"
                    name="status"
                    value={false}
                    required
                    checked={formData.status === false}
                    onChange={handleInputChange}
                  />
                  Not looking for new members
                </label>
              </div>
            </div>
            <div class={style.entryField}>
              <label class={style.fieldLabel} for="image">
                Project Image
                <ToolTip
                  message={
                    "Choose a good picture to represent your project! (.jpg or .jpeg)"
                  }
                />
              </label>
              <input
                name="image"
                class={style.fileInput}
                type="file"
                accept=".jpg, .jpeg"
                onChange={handleInputChange}
              />
            </div>
            <div class={style.buttonContainer}>
              <button class={style.createButton}>Create project</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default CreateProjectPage;
