import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "../styles/modules/CreationForms.module.css";
import CharacterCounterInput from "../components/CharacterCounterInput";
import api from "../config/AxiosConfig";
import { toast } from "react-toastify";
import MyDateTimePicker from "../components/DateTimePicker";
import ToolTip from "../components/ToolTip";
import { useParams } from "react-router-dom";

function AddEventPage() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    startDateTime: "YYYY-MM-DDT00:00:00.000",
    endDateTime: "YYYY-MM-DDT00:00:00.000",
    location: "",
  });
  const { projectId } = useParams();

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Submitting:", formData);

    if (!formData.startDateTime || formData.startDateTime === "YYYY-MM-DDT00:00:00.000") {
      toast.error("Please select a valid start date & time.");
      return;
    }

    if (!formData.endDateTime || formData.endDateTime === "YYYY-MM-DDT00:00:00.000") {
      toast.error("Please select a valid end date & time.");
      return;
    }

    const start = new Date(formData.startDateTime);
    const end = new Date(formData.endDateTime);
    if (end <= start) {
      toast.error("End time must be after start time.");
      return;
    }

    try {
      const response = await api.post(`/${projectId}/addEvent`, formData, {
        withCredentials: true,
      });
      if (response.status === 200 || response.status === 201) {
        toast.success("Event created successfully!");
        navigate(`/projects/${projectId}`);
      }
    } catch (err) {
      console.error("Event creation error:", err);
      toast.error("Failed to create event.");
    }
  };

  return (
    <div class={style.container}>
      <div>
        <h1 class={style.title}>
          Create A New Event
          <ToolTip
            message={"Fill in the fields below to schedule a new event!"}
          />
        </h1>
        <form class={style.formBody} onSubmit={handleSubmit}>
          <div class={style.entryField}>
            <label className={style.fieldLabel} htmlFor="title">
              Event Name:
              <ToolTip
                message={
                  "Give your new event a name. There's no need to include event location, date, or time in the title."
                }
              />
            </label>
            <CharacterCounterInput
              type="input"
              className={style.wideTextEntry}
              name="title"
              id="title"
              required
              maxChars={50}
              value={formData.title}
              onChange={handleInputChange}
            />
          </div>
          <div class={style.entryField}>
            <label className={style.fieldLabel} htmlFor="description">
              Event Description:
              <ToolTip
                message={
                  "What should your project members know about this meeting?"
                }
              />
            </label>
            <CharacterCounterInput
              type="textarea"
              className={style.largeTextEntry}
              name="description"
              id="description"
              required
              maxChars={2000}
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <div class={style.entryField}>
            <label className={style.fieldLabel}>
              Start Time:
              <ToolTip
                message={
                  "Click the calendar icon to set the event's start date and time."
                }
              />
            </label>
            <MyDateTimePicker
              value={formData.startDateTime}
              onChange={(val) => {
                console.log(val);
                setFormData((prev) => ({ ...prev, startDateTime: val }));
              }}
            />
          </div>

          <div class={style.entryField}>
            <label className={style.fieldLabel}>
              End Time:
              <ToolTip
                message={
                  "Click the calendar icon to set the event's end date and time."
                }
              />
            </label>
            <MyDateTimePicker
              value={formData.endDateTime}
              onChange={(val) =>
                setFormData((prev) => ({ ...prev, endDateTime: val }))
              }
            />
          </div>

          <div class={style.entryField}>
            <label className={style.fieldLabel} htmlFor="location">
              Location:
              <ToolTip
                message={
                  "Let your team members know where this event is being held. If it's an online event, include a link to it."
                }
              />
            </label>
            <CharacterCounterInput
              type="input"
              className={style.wideTextEntry}
              name="location"
              id="location"
              required
              maxChars={50}
              value={formData.location}
              onChange={handleInputChange}
            />
          </div>
          <div class={style.buttonContainer}>
            <button class={style.createButton}>Create Event</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddEventPage;
