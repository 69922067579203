import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig.js";
import { useNavigate, useParams } from "react-router-dom";
import joinedIcon from "../assets/JoinedIcon.png";
import style from "../styles/modules/UserPage.module.css";
import Image from "../components/Image.js";
import { useUser } from "../context/UserContext";
import EditUserPage from "./EditUserPage.js";
import MessageButton from "../components/MessageButton";
import defaultProfilePhoto from "../assets/default_profile_photo.jpg"
import InvitePopup from "../components/InvitePopup.js"
import defaultBannerPhoto from "../assets/default_banner_photo.jpg";
import ProjectCard from "../components/ProjectCard.js";
import IconLinkedIn from "../components/IconLinkedIn.js";
import IconInstagram from "../components/IconInstagram.js";

function Projects({ projectsId }) {
  if (typeof projectsId == "undefined" || projectsId.length === 0) {
    return (
      <>
        <p>This user is not currently affiliated with any projects.</p>
      </>
    );
  }
  return (
    <div class={style.projects}>
      {projectsId.map((id, i) => (
        <ProjectCard id={id} showJoined={true} />
      ))}
    </div>
  );
}

function UserTags({ tags }) {
  if (typeof tags == "undefined") {
    return <></>;
  }
  return (
    <div class={style.tags}>
      {tags.map((tag, i) => (
        <div class={style.tag} key={i}>
          {" "}
          {tag}
        </div>
      ))}
    </div>
  );
}

function ProjectOption({ id }) {
  const [projectInfo, setProjectInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/projects/${id}`)
      .then((response) => {
        setProjectInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the project data!", error);
      });
  }, []);
  return <option value={id}>{projectInfo.title}</option>;
}

function UserPage() {
  const { userId } = useParams();
  const [message, setMessage] = useState([]);
  const [coverImage, setCoverId] = useState(null);
  const [inviteMessage, setInviteMessage] = useState("");
  const [inviteProject, setInviteProject] = useState("");
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(defaultBannerPhoto);

  useEffect(() => {
    // Fetch data from the backend
    // TODO: Change to backend url
    api
      .get(`/users/${userId}`)
      .then((response) => {
        setMessage(response.data); // Assuming the response is a plain text message
        console.log(message);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  if (editMode) {
    return (
      <>
        <EditUserPage userData={message} />
      </>
    );
  }

  const HandleInvite = async (e) => {
    let finalMessage = inviteMessage;

    if (finalMessage.trim() === "") {
        finalMessage = "You would be a great fit for this project!";
        setInviteMessage(finalMessage);
    }
    try {
      var now = new Date();
      now = now.toISOString();
      var inviteProjectToSend = inviteProject;
      if (inviteProjectToSend == "") {
        inviteProjectToSend = user.projectsId[0];
      }
      const inviteResponse = await api.post(
        `/users/invite/${user.id}/${message.id}/${inviteProjectToSend}`,
        {msg: finalMessage, ts: now}
      );
      if (inviteResponse.status === 200 || inviteResponse.status === 201) {
        console.log("Successfully sent project invitation");
        setInviteMessage("");
      }
    } catch (error) {
      console.error("Error inviting user to the project:", error);
    }
  };

  const HandleEdit = (e) => {
    navigate(`/users/${userId}/edit`);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      handleUpload(file);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post("/images/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Upload success:", response.data);
      setCoverId(response.data);
      uploadCoverImage(response.data);
      // alert("Cover image updated!");
    } catch (error) {
      console.error("Upload failed:", error);
      // alert("Failed to update cover image.");
    }
  };

  const uploadCoverImage = async (coverId) => {
    if (!coverId || !user?.id) return; 
  
    try {
      await api.post(`/users/${user.id}/coverImage/${coverId}`);
      console.log("Cover image updated successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error updating cover image:", error);
    }
  };

  const handleRemoveCoverImage = async() =>{
    if (!user?.id) return; 
    try {
      await api.delete(`/users/${user.id}/deleteCoverImage`);
      window.location.reload();
    } catch (error) {
    }
  }

  console.log("linkedin link");
  console.log(message.linkedInLink);

  return (
    <>
      <div class={style.userHeader}>
      {message && message.coverImageId != null ? (
            <Image id={message.coverImageId} styleType={style.projectPhoto} />
          ) : (
            <img
              src={defaultBannerPhoto}
              className={style.projectPhoto}
              alt="Default profile photo"
            />
      )}
      {user && user.id == userId ? (
        <div>
          <input
          type="file"
          accept="image/*"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <div className={style.imageButtonWrapper}>
        <button className={style.changeImageButton} onClick={() => document.getElementById("fileInput").click()}>
        Change Image
        </button>
        <button className={style.changeImageButton} onClick={handleRemoveCoverImage}>
        Remove Image
        </button>
        </div>
        </div>
        ):(<></>
      )}
      {message.profilePhotoId ? (
      <Image id={message.profilePhotoId} styleType={style.profilePicture} />
        ): <img src={defaultProfilePhoto} className={style.profilePicture} alt="Default Profile" />}
      </div>
      <div class={style.infoSection}>
        <div class={style.nameColumn}>
          <h1 class={style.h1}>{message.name}
          <IconLinkedIn link={message.linkedInLink}/>
          <IconInstagram link={message.instagramLink}/>
          </h1>
          <div class={style.pronouns}>
            {message.pronouns !== null &&
            message.pronouns !== "Other" &&
            message.pronouns !== "Unspecified" ? (
              <h4>{message.pronouns}</h4>
            ) : (
              <></>
            )}
          </div>
          <div class={style.major}>
            <h3 class={style.h3}>{message.major}</h3>
          </div>
        </div>
        <div class={style.contactColumn}>
          {user && user.id == userId ? (
            <>
            <h2 class={style.h2}>{message.school}</h2>
            <h2 class={style.h2}>{message.email}</h2>
            <button
              className={style.editButton}
              onClick={HandleEdit}
              type="button"
            >
              {" "}
              Edit Profile{" "}
            </button>
            </>
          ) : (
           <></>
          )}
        </div>
        <div class={style.contactColumn}>
          {user&& user.id == userId?(
            <>
            </>
          ): (
            <><h2 class={style.h2}>{message.school}</h2>
            <h2 class={style.h2}>{message.email}</h2></>
          )}
          <div className={style.buttonsRow} >
            <MessageButton toId={userId} />
            <InvitePopup
              HandleInvite={HandleInvite}
              inviteMessage={inviteMessage}
              setInviteMessage={setInviteMessage}
              currentUser={user}
              inviteProject={inviteProject}
              setInviteProject={setInviteProject}
              userId={userId}
              projects={message.projectsId}
            />
          </div>
        </div>
        </div>
      <div class={style.userPageContent}>
        {message.description ? (
          <>
            <h3>About Me</h3>
            <p>{message.description}</p>
          </>
        ) : (
          <></>
        )}
        <h3>Projects</h3>
        <Projects projectsId={message.projectsId} />
        {message.skills ? (
          <>
            <h3>Skills</h3>
            <UserTags tags={message.skills} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default UserPage;
